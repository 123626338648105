import React, { useEffect, useRef, useReducer } from 'react';
import axios from 'axios';
import FilterItem from './messages/FilterItem.jsx';
import DataRow from './logs/DataRow.jsx';
import DatePicker from './DatePicker.jsx';
import moment from 'moment';
import Placeholder from './Placeholder.jsx';

function LogsContainer(props) {
  const [state, setState] = useReducer(
    (prev_state, new_state) => ({ ...prev_state, ...new_state }),
    {
      data: props.data,
      columns: props.columns,
      sort_by: Object.keys(props.columns)[0],
      sort_by_order: 'desc',
      download_column: props.download_column,
      selected_rows: [],
      search_string: '',
      is_loading: false,
      selected_type: props.selected_type,
      start_date: props.start_date,
      end_date: props.end_date,
    }
  );

  const set_type = type => setState({
    selected_type: type,
    is_loading: true,
  });

  const column_size = column => {
    if(column === 'turn' && state.selected_type === 'mcs_slaughters' ) return 1;
    return Object.keys(state.columns).length > 5 ? 2 : 3;
  }

  const additional_style_for = column => {
    return {
      total_delivered_quantity: { maxWidth: 110 },
      avg_weight:               { maxWidth: 100 },
      sorting_efficiency:       { maxWidth: 120 },
      avg_lean_meat:            { maxWidth: 120 },
      turn:                     { minWidth: 90 },
    }[column];
  }

  const is_first_run = useRef(true);
  const is_selected = type => (state.selected_type === type);

  const set_start_date = date => setState({ is_loading: true, start_date: date });

  const set_end_date = date => setState({ is_loading: true, end_date: date });

  const get_data = () => {
    const {
      selected_type,
      start_date,
      end_date,
    } = state;

    axios.get(props.data_path, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params: {
        type: selected_type,
        start_date,
        end_date,
      },
    }).then(({ data: { data, columns, actual_url, download_column } }) => {
      window.history.pushState({}, '', actual_url);
      setState({
        data,
        columns,
        sort_by: Object.keys(columns)[0],
        sort_by_order: 'desc',
        download_column,
        selected_rows: [],
        search_string: '',
        is_loading: false,
      });
    }).catch(error => console.log(error));
  };

  const filter_data = () => state.data.filter(({ turn }) => turn.toString().includes(state.search_string));

  const row_id_by_type = (row_id) => {
    if (state.selected_type !== 'mcs_slaughters') return row_id;

    return `${row_id}_${state.data[row_id].turn}`;
  };

  const select_all_rows = () => {
    const { data } = state;
    const all_rows = filter_data(data).map(({ id }) => row_id_by_type(id));
    setState({ selected_rows: all_rows });
  };

  const deselect_all_rows = () => setState({ selected_rows: [] });

  const all_selected = () => state.selected_rows.length === filter_data(state.data).length;

  const select_row = row_id => setState({ selected_rows: [...state.selected_rows, row_id_by_type(row_id)] });

  const deselect_row = row_id => setState({ selected_rows: state.selected_rows.filter(id => id !== row_id_by_type(row_id)) });

  const update_sort_by_order = (sort_by, sort_by_order) => setState({ sort_by, sort_by_order });

  const sortable = (column) => {
    if (!(parseFloat(state.data[0][column]))) return false;

    return true;
  };

  useEffect(() => {
    if (is_first_run.current) {
      is_first_run.current = false;
      return;
    }

    get_data();
  }, [
    state.selected_type,
    state.start_date,
    state.end_date,
  ]);

  const {
    data,
    columns,
    sort_by,
    sort_by_order,
    download_column,
    selected_rows,
    search_string,
    is_loading,
    start_date,
    end_date,
  } = state;

  const sorted_data = () => {
    const filtered_data = filter_data(data);
    const value_for_sorting = (original_data) => {
      if (typeof original_data !== 'string') return original_data;

      return original_data.replace(/\D/g, '');
    };

    if (sort_by_order === 'asc') return filtered_data.sort((a, b) => parseFloat(value_for_sorting(a[sort_by])) - parseFloat(value_for_sorting(b[sort_by])));

    return filtered_data.sort((a, b) => (parseFloat(value_for_sorting(a[sort_by])) > parseFloat(value_for_sorting(b[sort_by])) ? -1 : 1));
  };

  const export_url = () => {
    const { selected_type } = state;
    const base_url = `${props.data_export_path}/?type=${selected_type}`;

    const formatted_start_date = moment(start_date).format();
    const formatted_end_date = moment(end_date).format();

    if (!selected_rows.length) return `${base_url}&start_date=${formatted_start_date}&end_date=${formatted_end_date}`;

    const selected_rows_params = selected_rows.map(rid => `&selected_rows[]=${rid}`).join('');

    return `${base_url}${selected_rows_params}&start_date=${formatted_start_date}&end_date=${formatted_end_date}`;
  };

  const export_link = () => !is_loading && !!state.data.length && <div className="flex flex-center flex-justify-end fs-12 lg-flex-reverse-column">
    <a
      href={export_url()}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-center lg-col-12 lg-flex-justify-end lg-mb3"
    >
      <img
        className="mr1"
        src="/icons/file-table.svg"
        alt="XLS icon"
      />
      { selected_rows.length === 0 ? 'Összes adat(ok) exportálása XLS-ben' : 'Kiválasztott adatok exportálása XLS-ben' }
    </a>
  </div>;

  return <>
    <div className="flex flex-center flex-justify mb-40 sm-block">
      <h2 className="fs-30 lg-col-12 lg-mb3">Könyvelt adatok</h2>
      <div className="col-6 lg-col-12">
        <div className="flex flex-wrap flex-justify-end mxn2">
          <div className="col-6 px2 sm-col-12 sm-m0 sm-mb3 relative">
            <i
              className="fal fa-search absolute left-0 ml2 top-50p transform-vertical-center"
              style={{ left: 15 }}
            />
            <input
              className="input"
              type="text"
              placeholder="Keresés turnusokban..."
              onChange={(e) => {
                if (isNaN(e.target.value)) return;

                setState({ search_string: e.target.value });
              }}
              style={{ paddingLeft: '45px' }}
              value={search_string}
              maxLength="20"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap fs-13 mb-40">
      {
        props.types.map(({ type, label }) => <FilterItem
          key={type}
          type={type}
          label={label}
          selected={is_selected(type)}
          set_type={set_type}
        />)
      }
    </div>
    <div className="flex flex-justify flex-end">
      <div className="flex">
        <div className="mr2">
          <span className="text-mine-shaft-o-64 mt1">Mettől</span>
          <div className="flex flex-center relative">
            <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
            <DatePicker
              selected_date={start_date}
              set_date={set_start_date}
              input_props={{ className: 'input input--pl3' }}
            />
          </div>
        </div>
        <div>
          <span className="text-mine-shaft-o-64 mt1">Meddig</span>
          <div className="flex flex-center relative">
            <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
            <DatePicker
              selected_date={end_date}
              set_date={set_end_date}
              input_props={{ className: 'input input--pl3' }}
            />
          </div>
        </div>
      </div>
      { export_link() }
    </div>
    <div className="lg-overflow-auto mb-40 mt2">
      {
        is_loading
        && <Placeholder text="Adatok betöltése folyamatban..." />
        || (state.data.length && <div className="table-responsive-container">
          <div className="bg-athens-gray flex fs-13 lh-40 mb1 rounded-8 text-mine-shaft-o-64">
            <div className="flex flex-center pl1" style={{ width: '5%' }}>
              <input
                id="all"
                className="custom-checkbox hidden"
                type="checkbox"
                checked={all_selected()}
                onChange={() => {
                  if (all_selected()) return deselect_all_rows();

                  return select_all_rows();
                }}
              />
              <label className="custom-checkbox-label col-1" htmlFor="all">&nbsp;</label>
            </div>
            {
              Object.keys(columns).map((column) => {
                const label = columns[column];
                const opacity = (order) => {
                  if (sort_by !== column) return 0.3;
                  if (sort_by_order !== order) return 0.3;

                  return 1;
                };

                return <div key={column} className={`flex flex-center col-${column_size(column)}`} style={additional_style_for(column)}>
                  <div className={`${column === sort_by ? 'bold' : ''}`}>
                    { label }
                  </div>
                  <div className="flex flex-column lh-normal ml1">
                    {
                      sortable(column) && <>
                        <a
                          href="#sortByOrderAsc"
                          onClick={(e) => {
                            e.preventDefault();

                            update_sort_by_order(column, 'asc');
                          }}
                        >
                          <i
                            style={{ opacity: opacity('asc') }}
                            className="fal fa-chevron-up fs-11"
                          />
                        </a>
                        <a
                          href="#sortByOrderDesc"
                          onClick={(e) => {
                            e.preventDefault();

                            update_sort_by_order(column, 'desc');
                          }}
                        >
                          <i
                            style={{ opacity: opacity('desc') }}
                            className="fal fa-chevron-down fs-11"
                          />
                        </a>
                      </>
                    }
                  </div>
                </div>;
              })
            }
            {
              download_column && <div className="col-2">&nbsp;</div>
            }
          </div>
          {
            sorted_data().map(d => <DataRow
              key={d.id}
              row_data={d}
              columns={columns}
              selected={selected_rows.includes(row_id_by_type(d.id))}
              select_row={select_row}
              deselect_row={deselect_row}
              sort_by={sort_by}
              column_size={column_size}
              download_column={download_column}
              selected_type={state.selected_type}
              additional_style_for={additional_style_for}
            />)
          }
        </div>
        || <Placeholder />
        )
      }
    </div>
    { export_link() }
  </>;
}

export default LogsContainer;
