import React, { useState } from 'react';
import ArticleLinks from './ArticleLinks.jsx';

function ArticlesByYear({ year, articles_by_month }) {
  const opened_initially = () => {
    const current_year = new Date().getFullYear();
    if (year === current_year) return true;
    return false;
  }

  const [open, toggle_year] = useState(opened_initially);

  const month_name = month_number => ({
    1: 'Január',
    2: 'Február',
    3: 'Március',
    4: 'Április',
    5: 'Május',
    6: 'Június',
    7: 'Július',
    8: 'Augusztus',
    9: 'Szeptember',
    10: 'Október',
    11: 'November',
    12: 'December',
  }[month_number]);

  const articles_count = Object.values(articles_by_month).map(v => v.length).reduce((a, b) => a + b, 0);

  return (
    <div>
      <a
        className="flex flex-justify mb2 text-mine-shaft-o-64"
        href="#toggleYear"
        onClick={(e) => {
          e.preventDefault();
          toggle_year(!open);
        }}
      >
        { `${year} (${articles_count})` }

        <i className={`fal ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </a>

      <div style={{ display: open ? 'block' : 'none' }}>
        {
          Object.keys(articles_by_month).map(month_number => <ArticleLinks
            key={month_number}
            month={month_name(month_number)}
            article_links={articles_by_month[month_number]}
          />)
        }
      </div>
    </div>
  );
}

export default ArticlesByYear;
