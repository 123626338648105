import React, { useReducer } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

function MassMessage({ mass_messages_path }) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      subject: '',
      message: '',
      can_submit: false,
      show_message_modal: false,
      show_confirmation_modal: false,
    }
  );

  const {
    subject,
    message,
    can_submit,
    show_message_modal,
    show_confirmation_modal,
  } = state;

  const show_mass_message_flash = (flash_message) => {
    const message_element = document.querySelector('.js-mass-message-modal .message');
    message_element.textContent = flash_message;

    showModal('.js-mass-message-modal');
    setTimeout(() => closeModal(), 3000);
  };

  const send_message = () => {
    setState({ can_submit: false });

    const csrf_token = document.querySelector('meta[name=csrf-token]').content;

    axios.post(mass_messages_path, { subject, message }, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf_token,
      },
    }).then(() => {
      setState({
        can_submit: true,
        subject: '',
        message: '',
        show_message_modal: false,
        show_confirmation_modal: false,
      });

      show_mass_message_flash('Üzenetek elküldve');
    }).catch(() => {
      setState({
        can_submit: true,
        subject: '',
        message: '',
        show_message_modal: false,
        show_confirmation_modal: false,
      });

      show_mass_message_flash('Hiba üzenetküldés közben!');
    });
  };

  const update_subject = content => setState({ subject: content });
  const update_message = content => setState({ can_submit: !!content.length, message: content });

  return (
    <div>
      <button
        type="button"
        onClick={() => setState({ show_message_modal: true })}
        className="btn btn--primary-cerulean mx2 sm-m0 sm-mb2"
      >
        Üzenet mindenkinek
      </button>
      <Modal
        ariaHideApp={false}
        isOpen={show_message_modal}
        closeTimeoutMS={200}
        onRequestClose={() => setState({ show_message_modal: false })}
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 6,
          },
          content: {
            height: 350,
            width: '50%',
            top: '35%',
            margin: '0 auto',
          },
        }}
      >
        <div className="flex flex-column flex-justify" style={{ height: '100%' }}>
          <a
            href="#close"
            className="absolute mr2 mt2 right-0 top-0"
            onClick={(e) => {
              e.preventDefault();
              setState({ show_message_modal: false });
            }}
            style={{ cursor: 'pointer' }}
          >
            <i className="fas fa-times color-blue" />
          </a>
          <div className="fs-22 bold center text-mine-shaft-o-64">Üzenet mindenkinek</div>
          <input
            className="input mt3"
            type="text"
            placeholder="Üzenet tárgya..."
            value={subject}
            onChange={e => update_subject(e.target.value)}
            style={{
              fontSize: 18,
              padding: '1rem',
            }}
          />
          <textarea
            className="textarea col-12 my2 p2"
            placeholder="Üzenet tartalma..."
            rows="10"
            value={message}
            onChange={e => update_message(e.target.value)}
            style={{
              resize: 'none',
              height: 'auto',
              fontSize: 18,
            }}
          />
          <div className="flex flex-justify-end">
            <button
              type="button"
              className={`btn btn--primary p1 border border-athens-gray ${can_submit ? '' : 'disabled'}`}
              disabled={!can_submit}
              onClick={() => setState({ show_confirmation_modal: true })}
            >
              Küldés
            </button>
          </div>
        </div>
      </Modal>
      {
        show_confirmation_modal && <Modal
          ariaHideApp={false}
          isOpen={show_confirmation_modal}
          closeTimeoutMS={200}
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 7,
            },
            content: {
              height: 250,
              width: '40%',
              top: '35%',
              margin: '0 auto',
            },
          }}
        >
          <div className="flex flex-column flex-justify" style={{ height: '100%' }}>
            <div className="fs-22 bold center text-mine-shaft-o-64">Biztos vagy benne, hogy mindenkinek üzenetet küldesz?</div>
            <div className="col-12 flex flex-justify">
              <a
                href="#closeConfirmationModal"
                className="btn btn--danger"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ show_confirmation_modal: false });
                }}
              >
                Nem
              </a>
              <a
                href="#sendMassMessage"
                className="btn btn--primary"
                onClick={(e) => {
                  e.preventDefault();
                  send_message();
                }}
              >
                Igen
              </a>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
}

export default MassMessage;
