import React from 'react';

export default function ReportsTable({ categories, columns, sum }) {
  function extra_class(column) {
    return {
      category: 'left-align col-2',
      percent: 'right-align col-4',
    }[column] || 'right-align col-3';
  }

  return (
    <div className="table-responsive-overflow">
      <div className="table-responsive-container">
        <div className="bg-athens-gray flex fs-13 py1 rounded-4">
          {
            Object.keys(columns).map(column => (<div
              className={`px2 ${extra_class(column)}`}
              key={columns[column]}
            >
              {columns[column]}
            </div>))
          }
        </div>
        {
         Object.keys(categories).map(category => (
           <div key={category} className="flex fs-13 py1"> {
                Object.keys(columns).map(column => (
                  <div
                    key={column + category}
                    className={`px2 ${extra_class(column)}`}
                  >
                    {categories[category][column]}
                  </div>
                ))
              }
           </div>
         ))
        }
        <div className="bg-athens-gray flex fs-13 py1 rounded-4">
          <div className="col-2 px2 left-align">
            Összesen:
          </div>
          <div className="col-3 px2 right-align">
            {sum.quantity}
          </div>
          <div className="col-3 px2 right-align">
            {sum.weight}
          </div>
          <div className="col-3 px2 right-align">
            {sum.average}
          </div>
          <div className="col-4 px2 right-align">
            {sum.percent}
          </div>
        </div>
      </div>
    </div>
  );
}
