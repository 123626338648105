import React, { useState } from 'react';

function ArticleLinks({ month, article_links }) {
  const [open, toggle_month] = useState(false);

  const articles_count = article_links.length;

  return (
    <div className="pl2 text-mine-shaft-o-64">
      <a
        className="flex flex-justify mb2 text-mine-shaft-o-64"
        href="#toggleMonth"
        onClick={(e) => {
          e.preventDefault();
          toggle_month(!open);
        }}
      >
        { `${month} (${articles_count})` }

        <i className={`fal ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
      </a>

      <div
        className="pl2"
        style={{ display: open ? 'block' : 'none' }}
      >
        {
          article_links.map(al => <div
            key={al.url}
          >
            <a
              className="block mb2 text-mine-shaft-o-64"
              href={al.url}
            >
              { al.title }
            </a>
          </div>)
        }
      </div>
    </div>
  );
}

export default ArticleLinks;
