import React from 'react';
import ArticleCard from './ArticleCard.jsx';

function Articles({ articles }) {
  return (
    <div className="flex flex-wrap mxn2">
      {
        articles.map(article => (
          <div className="col-6 mb4 px2 sm-col-12" key={article.url}>
            <ArticleCard {...article} />
          </div>
        ))
      }
    </div>
  );
}

export default Articles;
