import React from 'react';

function ArticleCard({
  article_tags,
  author,
  lead_picture_url,
  lead,
  published_at_formatted,
  title,
  url,
}) {
  return (
    <div className="relative">
      <a href={url}>
        <div
          className="bg-center bg-cover bg-no-repeat block mb2 mx-auto rounded-custom-16"
          style={{ backgroundImage: `url(${lead_picture_url})`, height: '200px' }}
        />
      </a>

      <p className="fs-22 mb2">
        <a href={url}>{title}</a>
      </p>

      <p className="mb2 text-mine-shaft-o-64" dangerouslySetInnerHTML={{ __html: lead }}/>

      <p className="fs-13">
        {author}

        <br />

        {
          article_tags.map(({ name, url }, index) => (
            <a
              href={url}
              key={url}
            >
              {name}{index + 1 !== article_tags.length && ', '}
            </a>
          ))
        }

        { article_tags.length > 0 && <span className="mx1">•</span> }

        {published_at_formatted}
      </p>
    </div>
  );
}

export default ArticleCard;
