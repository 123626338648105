import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Hash } from 'crypto';

function multiSort(array, sortObject = {}) {
  const sortKeys = Object.keys(sortObject);

  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
      return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
      sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1 : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
  }

  const keySort = (a, b, direction) => {
      direction = direction !== null ? direction : 1;

      if (a === b) { // If the values are the same, do not switch positions.
          return 0;
      }

      // If b > a, multiply by -1 to get the reverse direction.
      return a > b ? direction : -1 * direction;
  };

  return array.sort((a, b) => {
      let sorted = 0;
      let index = 0;

      // Loop until sorted (-1 or 1) or until the sort keys have been processed.
      while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
              const direction = sortObject[key];

              sorted = keySort(a[key], b[key], direction);
              index++;
          }
      }

      return sorted;
  });
}
export default function ReportChart({
  currentUser,
  reportData,
  tableData,
  groupedByPartner,
  limits,
}) {
  const [chartType, setChartType] = useState('vallalkozoi_dij_netto');

  const currentAdmin = currentUser.role == 'super_admin';
  const allAvgData = tableData.group_avg[chartType];
  const ownAvgData = tableData.own_group_avg[chartType];
  const sortObject = {};
  sortObject[chartType] = (chartType === 'elhullas' || chartType === 'tak_fajlag') ? 'asc' : 'desc' ;
  const rawData = multiSort(groupedByPartner, sortObject );
  const orderedChartData = rawData.map(reports => reports[chartType]);
  orderedChartData.unshift('');
  const chartTypes = [
    { name: 'elhullas', label: 'Elhullási százalék', unit: '(%)' },
    { name: 'gyarapodas', label: 'Napi súlygyarapodás', unit: '(g/nap)' },
    { name: 'hatekonysag', label: 'Válogatás hatékonysága', unit: '(%)' },
    { name: 'tak_fajlag', label: 'Fajlagos takarmányfelhasználás', unit: '(kg/kg)' },
    { name: 'vallalkozoi_dij_netto', label: 'Vállalkozói díj', unit: '(Ft/kg)' },
  ];

  const chartLabel = chartTypes.filter(type => type.name === chartType).map(filteredChartTypes => filteredChartTypes.label).toString();
  const chartUnit = chartTypes.filter(type => type.name === chartType).map(filteredChartTypes => filteredChartTypes.unit).toString();

  const getClosest = (array, target) => {
    return array.reduce((prev, curr) => {
      return (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev);
    });
  }

  const limitChartType = limits.filter(limit => limit.limit_type == chartType);
  const allAvgArray = Array(orderedChartData.length).fill(0).fill(allAvgData, orderedChartData.indexOf(getClosest(orderedChartData, allAvgData)), orderedChartData.indexOf(getClosest(orderedChartData, allAvgData))+1);

  const ownAvgArray = Array(orderedChartData.length).fill(0).fill(ownAvgData, orderedChartData.indexOf(getClosest(orderedChartData, ownAvgData)), orderedChartData.indexOf(getClosest(orderedChartData, ownAvgData))+1);

  const limitLinesArray = limitChartType.map(limit => Array(orderedChartData.length).fill(limit.limit_top));

  let seriesArr = [
    {
      name: chartLabel,
      data: orderedChartData,
      type: 'area',
    },
    {
      name: `${chartLabel} összes átlag`,
      data: allAvgArray,
      type: 'column',
    },
    {
      name: `${chartLabel} saját átlag`,
      data: ownAvgArray,
      type: 'column',
    },
  ];
  limitChartType.forEach((item, i) => {
    seriesArr = [...seriesArr, {
      name: item.limit_text,
      data: limitLinesArray[i],
      type: 'line',
      color: item.limit_color,
    }]
  });

  Highcharts.setOptions({
    lang: {
      numericSymbols: [null, null],
    },
  });
  const options = {
    tooltip: {
      formatter: function () {
        if (currentAdmin) {
          return 'Partner: <b>'+ rawData[this.x-1].partner +'</b> ' + chartLabel +
          ': <b>' + this.y + '</b> ('+ this.x + '.)';
        }
        else {
          return chartLabel +
          ': <b>' + this.y + '</b>';
        }
      },
    },
    title: {
      text: chartLabel,
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: chartUnit,
      },
      // lineWidth: 1,
      type: 'logarithmic',
      minorTickInterval: 0.1,
      // offset: -9,
    },
    xAxis: {
      title: {
        text: 'Partnerek száma',
      },
      min: 1,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    series: seriesArr,
  };

  return (
    <section className="bg-athens-gray">
      <div className="container container--main py4">
        <div>
        <h2 className="title">Partner ranglista</h2> 
        <p className='mb3'>Saját turnusok átlagos teljesítménye alapján</p>
        </div>
        <div className="mb3">
          {
            chartTypes.map(type => (
              <button
                className={`${chartType === type.name && 'bg-pistachio text-white' || 'bg-white'} border-none cursor-pointer lg-mb1 lh-40 mr1 px2 rounded-4`}
                key={type.label}
                onClick={() => { setChartType(type.name); }}
                type="button"
              >
                {type.label}
              </button>
            ))
          }
        </div>

        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    </section>
  );
}
