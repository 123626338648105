import React from 'react';

function ArticleTags({ article_tags, selected_tag_ids, select_tag }) {
  return (
    <div className="mb-40">
      <p className="bold mb2">Témák</p>

      <div className="flex flex-wrap">
        {
          article_tags.map(({ id, name }) => <a
            className={`${selected_tag_ids.includes(id) ? 'bg-cerulean border-cerulean text-white' : 'border-mercury'} border fs-13 mb1 mr1 px2 rounded-16`}
            key={id}
            onClick={(e) => {
              e.preventDefault();
              select_tag(id);
            }}
            href="#selectTag"
          >
            {name}
          </a>)
        }
      </div>
    </div>
  );
}

export default ArticleTags;
