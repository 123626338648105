import React, { useReducer } from 'react';
import MouseTooltip from 'react-sticky-mouse-tooltip';
import moment from 'moment';

function DataRow({
  row_data,
  columns,
  column_size,
  selected,
  select_row,
  deselect_row,
  sort_by,
  download_column,
  additional_style_for,
}) {
  const { id } = row_data;
  const [state, setState] = useReducer(
    (prev_state, new_state) => ({ ...prev_state, ...new_state }),
    {
      tooltip_visible: false,
      tooltip_content: [],
    }
  );

  const format_date = (value) => {
    if (value === 0) return '0000.00.00.'

    return moment.unix(value).format('YYYY.MM.DD.')
  };

  const toggle_row = () => {
    if (selected) return deselect_row(id);

    return select_row(id);
  };

  const {
    tooltip_visible,
    tooltip_content,
  } = state;

  return <div className={`flex flex-center fs-13 lh-40 relative pointer rounded-4 bg-${selected ? 'aths-special' : 'white'}`}>
    <div className="flex flex-center pl1" style={{ width: '5%' }}>
      <input
        id={id}
        className="custom-checkbox hidden"
        type="checkbox"
        checked={selected}
        onChange={() => toggle_row()}
      />
      <label className="custom-checkbox-label col-1 pointer" htmlFor={id}>&nbsp;</label>
    </div>
    {
      Object.keys(columns).map(column => <div
        key={column}
        className={`col-${column_size(column)} ${column === sort_by ? 'bold' : ''}`}
        style={additional_style_for(column)}
        role="row"
        onClick={() => toggle_row()}
      >
        {
          column === 'balance_weight' && (row_data[column] === -1 ? 'Osztott fuvar' : row_data[column]) ||
          column === 'turn' && <>
            <span
              onMouseEnter={() => {
                const tc = Object.values(row_data.detailed_turn_data).map(({ label, value }) => <div className="z1 fs-18 my1">
                  <span className="bold">{label}:</span> {value}
                </div>);

                setState({
                  tooltip_visible: true,
                  tooltip_content: tc,
                });
              }}
              onMouseLeave={() => setState({ tooltip_visible: false })}
            >
              {row_data[column]}
            </span>
          </> || (column.includes('date') ? format_date(row_data[column]) : row_data[column])
        }
      </div>)
    }
    {
      download_column && <div className="col-2 right-align">
        <a
          href={row_data.download_path}
          target="_blank"
          rel="noopener noreferrer"
          className="text-cerulean relative mr1"
        >
          PDF letöltése
        </a>
      </div>
    }
    <MouseTooltip
      visible={tooltip_visible}
      offsetX={15}
      offsetY={10}
      className="rounded-5 bg-cod-gray text-white p1 block z1"
    >
      {tooltip_content}
    </MouseTooltip>
  </div>;
}

export default DataRow;
