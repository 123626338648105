import React from 'react';

function FilterItem({ type, label, selected, set_type }) {
  const bg_color = selected ? 'pistachio' : 'athens-gray';
  const fg_color = selected ? 'white' : 'mine-shaft';

  return <a
    href="#selectFilter"
    onClick={(e) => {
      e.preventDefault();
      set_type(type);
    }}
    className={`bg-${bg_color} lh-40 mr1 px2 rounded-4 sm-mb1 text-${fg_color}`}
  >
    {label}
  </a>;
}

export default FilterItem;
