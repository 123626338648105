import React from 'react';

function Step({ step }) {
  const color = (index) => {
    if (index + 1 > step) return 'athens-gray';

    return 'cerulean'
  };

  return (
    <div className="flex flex-justify relative mt2" style={{ width: 55 }}>
      <div className={`col-6 border-bottom border-${color(1)} absolute left-0`} style={{ top: 5, borderWidth: 2 }} />
      <div className={`col-6 border-bottom border-${color(2)} absolute left-50p`} style={{ top: 5, borderWidth: 2 }} />
      {
        [1, 2, 3].map((element, index) => <div
          key={element}
          className={`circle bg-${color(index)} z1`}
          style={{
            height: 12,
            width: 12,
            border: '2px solid white'
          }}
        />)
      }
    </div>
  );
}

export default Step;
