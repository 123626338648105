import React from 'react';

function Pager({ current_page, total_pages, select_page }) {
  const prev_page = current_page - 1;
  const next_page = current_page + 1;
  const prev_page_available = !!prev_page;
  const next_page_available = next_page <= total_pages;

  return <div className="flex">
    <a
      href="#selectPrevPage"
      onClick={(e) => {
        e.preventDefault();
        if (!prev_page_available) return;

        select_page(current_page - 1);
      }}
      className={`bg-athens-gray lh-40 mr1 px2 rounded-4 text-${prev_page_available ? 'mine-shaft' : 'gray'}`}
    >
      &lsaquo;
    </a>
    <span className="bg-athens-gray lh-40 mr1 px2 rounded-4 text-mine-shaft center" style={{ minWidth: 117 }}>
      { current_page } / { total_pages }
    </span>
    <a
      href="#selectNextPage"
      onClick={(e) => {
        e.preventDefault();
        if (!next_page_available) return;

        select_page(current_page + 1);
      }}
      className={`bg-athens-gray lh-40 mr1 px2 rounded-4 text-${next_page_available ? 'mine-shaft' : 'gray'}`}
    >
      &rsaquo;
    </a>
  </div>;
}

export default Pager;
