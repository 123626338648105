import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import Placeholder from '../Placeholder.jsx';
import ReportFilters from './ReportFilters.jsx';
import ReportsTable from './ReportsTable.jsx';

export default function SlaughterReports({
  initial_categories,
  initial_columns,
  initial_sum,
  partner_turns_and_bmrs_path,
  partner_mcs_slaughter_reports_path,
}) {
  const [select, set_select] = useState({
    start_date: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    turns: null,
    bmrs: null,
  });
  const [categories, set_categories] = useState(initial_categories);
  const [columns, set_columns] = useState(initial_columns);
  const [sum, set_sum] = useState(initial_sum);
  const [loading, set_loading] = useState(false);

  function get_reports() {
    if (loading) return;
    set_loading(true);
    axios.get(partner_mcs_slaughter_reports_path, {
      headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
      params: {
        start_date: select.start_date,
        end_date: select.end_date,
        turns: select.turns ? [select.turns.value] : undefined,
        bmrs: select.bmrs ? select.bmrs.map(b => b.value) : undefined,
      },
    }).then(({ data: { columns: new_columns, categories: new_categories, sum: new_sum } }) => {
      set_columns(new_columns);
      set_categories(new_categories);
      set_sum(new_sum);
      set_loading(false);
    });
  }

  return (
    <React.Fragment>
      <div className="mb-40">
        <p className="bold">MCS vágóhídi súlykategóriák összesítve a nyitott turnusokban</p>
      </div>
      <ReportFilters
        set_select={set_select}
        select={select}
        get_reports={get_reports}
        partner_turns_and_bmrs_path={partner_turns_and_bmrs_path}
        loading={loading}
      />
      { Object.keys(categories).length === 0
        ? <Placeholder height={300} />
        : <ReportsTable categories={categories} columns={columns} sum={sum} />
      }
    </React.Fragment>
  );
}
