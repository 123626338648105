import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import DatePicker from '../DatePicker.jsx';

export default function ReportFilters({
  select,
  set_select,
  get_reports,
  partner_turns_and_bmrs_path,
  loading,
}) {
  const [turn_selection, set_turn_selection] = useState([]);
  const [bmr_selection, set_bmr_selection] = useState([]);
  const [radio_type, set_radio_type] = useState('turn');

  function create_selection(items, extraValues) {
    return Object.keys(items).map(item => ({ value: item, ...extraValues(item) }));
  }

  function get_turns_and_bmrs() {
    axios.get(partner_turns_and_bmrs_path, {
      headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
    }).then(({ data: { turns, bmrs } }) => {
      set_turn_selection(create_selection(turns, turn => ({ dates: turns[turn], label: turn })));
      set_bmr_selection(create_selection(bmrs, bmr => ({ turn: bmrs[bmr].turn, date: bmrs[bmr].date, label: `${bmr} - ${bmrs[bmr].date.replaceAll('-', '.')}` })));
    });
  }

  useEffect(get_turns_and_bmrs, []);

  function handle_select(value, target) {
    set_select({ ...select, [target]: value });
  }

  function on_radio_change({ target: { value } }) {
    set_radio_type(value);
    set_select({ ...select, bmrs: null, turns: null });
  }

  function date_is_within_selected_date_interval(date) {
    return moment(date) >= moment(select.start_date) && moment(date) <= moment(select.end_date);
  }

  function filtered_turn_selection() {
    return turn_selection.filter(turn => turn.dates.filter(date_is_within_selected_date_interval).length > 0);
  }

  function filtered_bmr_selection() {
    const there_is_no_bmr_selected = !(select.bmrs && select.bmrs.length);
    return bmr_selection.filter(bmr => date_is_within_selected_date_interval(bmr.date) && (there_is_no_bmr_selected || select.bmrs[0].turn === bmr.turn));
  }

  return (
    <div className="mb-40 grid flex-end flex-justify grid-template-columns-15-15-10-45-10 lg-grid-template-columns-50-50 sm-grid-template-columns-100">
      <div className="px-5px grid-row-1 sm-grid-row-auto">
        <span className="text-mine-shaft-o-64 mt1">Mikortól</span>
        <div className="flex flex-center relative">
          <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
          <DatePicker selected_date={select.start_date} set_date={val => handle_select(val, 'start_date')} input_props={{ className: 'input input--pl3' }} />
        </div>
      </div>
      <div className="px-5px grid-row-1 sm-grid-row-auto">
        <span className="text-mine-shaft-o-64 mt1">Meddig</span>
        <div className="flex flex-center relative">
          <i className="fal fa-calendar absolute h3 left-0 mx1 text-mine-shaft-o-64 top-50p transform-vertical-center" />
          <DatePicker selected_date={select.end_date} set_date={val => handle_select(val, 'end_date')} input_props={{ className: 'input input--pl3' }} />
        </div>
      </div>
      <div className="px-5px grid-row-1 lg-grid-row-2 sm-grid-row-auto" onChange={on_radio_change}>
        <label htmlFor="turn" className="text-mine-shaft-o-64">
          <input className="mr1" type="radio" id="turn" name="select-type" value="turn" checked={radio_type === 'turn'} readOnly />
          Turnus
        </label>
        <br />
        <label htmlFor="bmr" className="text-mine-shaft-o-64">
          <input className="mr1" type="radio" id="bmr" name="select-type" value="bmr" checked={radio_type === 'bmr'} readOnly />
          BMR
        </label>
      </div>
      { radio_type === 'turn'
        ? <div key="turn-select" className="px-5px grid-row-1 lg-grid-row-2 sm-grid-row-auto">
          <span className="text-mine-shaft-o-64">Turnus</span>
          <Select isClearable name="turns" options={filtered_turn_selection()} onChange={val => handle_select(val, 'turns')} placeholder="Válassz!" />
        </div>
        : <div key="bmr-select" className="px-5px grid-row-1 lg-grid-row-2 sm-grid-row-auto">
          <span className="text-mine-shaft-o-64">BMR</span>
          <Select isMulti name="bmrs" options={filtered_bmr_selection()} onChange={val => handle_select(val, 'bmrs')} placeholder="Válassz!" />
        </div>
      }
      <button type="button" className="btn btn--primary px-5px grid-row-1 lg-grid-row-3 sm-grid-row-auto" onClick={get_reports}>
        <span className={`${loading ? 'mr1' : ''}`}>Szűrés</span>
        { loading ? <i className="fa fa-spinner fa-pulse" /> : null }
      </button>
    </div>
  );
}
