import React from 'react';
import Select from 'react-select';

export default function PartnerSelect({ partners, selected_partner }) {
  return (
    <Select
      name="partner-select"
      onChange={({ value }) => window.location = value}
      options={partners}
      isSearchable={false}
      value={selected_partner}
    />
  );
}
