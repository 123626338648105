import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

function DatePicker({ selected_date, set_date, input_props }) {
  const months = [
    'Január',
    'Február',
    'Március',
    'Április',
    'Május',
    'Június',
    'Július',
    'Augusztus',
    'Szeptember',
    'Október',
    'November',
    'December',
  ];

  const weekdaysLong = [
    'Vasárnap',
    'Hétfő',
    'Kedd',
    'Szerda',
    'Csütörtök',
    'Péntek',
    'Szombat',
  ];
  const weekdaysShort = ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'];

  const labels = { nextMonth: 'Következő hónap', previousMonth: 'Előző hónap' };

  return <DayPickerInput
    inputProps={input_props}
    value={selected_date}
    onDayChange={date => set_date(date)}
    placeholder="Dátumra szűrés"
    dayPickerProps={{
      months,
      weekdaysLong,
      weekdaysShort,
      firstDayOfWeek: 1,
      labels,
    }}
  />;
}

export default DatePicker;
