import React, { useState } from 'react';
import axios from 'axios';
import ImageGallery from 'react-image-gallery';
import ImageGalleryStyle from 'react-image-gallery/styles/scss/image-gallery.scss';
import Modal from 'react-modal';
import Step from './Step.jsx';

function RemoteMessageItem(props) {
  const {
    id,
    title,
    sub_title,
    icon,
    last_message_date,
    body,
    step,
    read_path,
    reply_to_path,
    can_be_replied,
    color,
    attachments,
    read_state: {
      unread_replies_count,
    },
    update_read_by_state,
  } = props;

  const [read_by_user, set_read_by_user] = useState(props.read_state.read_by_user);
  const [opened, toggle_opened] = useState(false);
  const [gallery_opened, gallery_showed] = useState(false);

  const csrf_token = document.querySelector('meta[name=csrf-token]').content;

  const update_read_by_user = () => {
    if (read_by_user) return;

    set_read_by_user(true);
    update_read_by_state(id);

    axios.post(read_path, { message_remote_id: id }, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf_token,
      },
    }).catch(error => console.log(error));
  };

  const images = attachments.map(({ attachment_url }) => ({ original: attachment_url, thumbnail: attachment_url }))

  return (
    <>
      <div
        className="block border-bottom border-athens-gray last-element-no-border px2 py2 pointer"
        onClick={(e) => {
          e.preventDefault();
          update_read_by_user();
          toggle_opened(!opened);
        }}
        role="row"
      >
        <div className="flex">
          <div style={{ minWidth: 64 }}>
            <img src={`/icons/message/${icon}`} alt="icon" />
          </div>
          <div className="flex-auto fs-13 text-mine-shaft-o-64">
            <div className="flex flex-justify sm-block">
              <p className="fs-15 text-mine-shaft">
                <span className={`${read_by_user ? '' : 'bold'}`}>{title}</span>
                {
                  !!unread_replies_count && <span className="inline-block ml1">
                    <span className="notification">{unread_replies_count}</span>
                  </span>
                }
              </p>
              <p dangerouslySetInnerHTML={{ __html: last_message_date }} />
            </div>
            <p className="mb1">{sub_title}</p>
            { !!step && <Step step={step} /> }
            {
              !!attachments.length && <div className="flex flex-justify flex-start fs-13 mt2">
                <div
                  className="flex flex-center bg-athens-gray pr2"
                  onClick={(e) => {
                    e.stopPropagation();
                    gallery_showed(true);
                  }}
                >
                  <img className="mr1" src="/icons/documents/img.svg" alt="icon" />
                  Csatolmányok
                </div>
              </div>
            }
          </div>
        </div>
        {
          opened && <div className="flex flex-end p2 bg-athens-gray rounded-5 sm-block mt1" style={{ backgroundColor: `${color}` }}>
            <div className="flex-auto pr2 sm-p0" dangerouslySetInnerHTML={{ __html: body }} />
              {
                can_be_replied && <a
                  className="btn btn--primary-white btn--lh-32 sm-mt2"
                  href="#replyToRemoteMessage"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    axios.post(reply_to_path, { message_remote_id: id, message_text: body }, {
                      headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRF-Token': csrf_token,
                      },
                    }).then(({ data: { path }}) => window.location = path)
                      .catch(error => console.log(error));
                  }}
                >
                  Válasz
                </a>
              }
          </div>
        }
      </div>
      {
        !!attachments.length &&
        <Modal
          ariaHideApp={false}
          isOpen={gallery_opened}
          closeTimeoutMS={200}
          onRequestClose={() => {
            gallery_showed(false);
          }}
          shouldCloseOnOverlayClick
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: 6,
            },
            content: {
              height: '100%',
              width: '50%',
              margin: '0 auto',
            },
          }}
        >
          <ImageGallery
            items={images}
          />
        </Modal>
      }
    </>
  );
}

export default RemoteMessageItem;
