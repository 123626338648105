import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import IconEmptyState from 'images/icons/file-empty.svg';
import 'react-day-picker/lib/style.css';
import Articles from './articles/Articles.jsx';
import ArticleTags from './articles/ArticleTags.jsx';
import ArticlesByYear from './articles/ArticlesByYear.jsx';
import DatePicker from './DatePicker.jsx';

function ArticlesContainer(props) {
  const [articles, set_articles] = useState(props.articles);
  const [selected_tag_ids, set_tag_ids] = useState(props.selected_tag_ids);
  const [start_date, set_start_date] = useState(props.start_date || '');
  const [end_date, set_end_date] = useState(props.end_date || '');
  const [query, set_query] = useState(props.query);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submittedQuery, setSubmittedQuery] = useState('');
  const [{ previous_page, current_page, next_page, total_pages }, set_pagination] = useState(props.pagination);

  const is_first_run = useRef(true);

  function select_tag(tag_id) {
    const new_selected_tag_ids = selected_tag_ids.includes(tag_id) ? selected_tag_ids.filter(id => id !== tag_id) : [...selected_tag_ids, tag_id];

    set_tag_ids(new_selected_tag_ids);
  }

  function update_query(str) {
    if (str.length > 20) return;

    set_query(str);
  }

  function send_request(params) {
    if (sidebarVisible) { setSidebarVisible(false); }
    setSubmitted(true);
    axios.get(props.articles_path, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params,
    }).then(({ data }) => {
      setSubmittedQuery(query);
      set_articles(data.articles);
      set_pagination(data.pagination);
      window.history.pushState({}, '', data.actual_url);
    }).catch(error => console.log(error));
  }

  function request_params(page = 1) {
    const params = {
      selected_tag_ids,
      page,
    };

    if (start_date) Object.assign(params, { start_date });
    if (end_date) Object.assign(params, { end_date });
    if (query.length) Object.assign(params, { query });

    return params;
  }

  useEffect(() => {
    if (is_first_run.current) {
      is_first_run.current = false;
      return;
    }

    send_request(request_params());
  }, [
    selected_tag_ids,
    start_date,
    end_date,
  ]);

  const emptyState = (
    <div className="center py4">
      <img alt="" src={IconEmptyState} />

      <p className="center fs-13 text-mine-shaft-o-64">Nincs találat a következő keresésre: "{submittedQuery}"</p>
    </div>
  );

  const foundText = (
    <React.Fragment>
      <br />
      <p className="fs-11 mb3 sm-m0 text-gray">{articles.length} találat az alábbi keresőszóra: "{submittedQuery}"</p>
    </React.Fragment>
  );

  const sidebarBtn = (
    <button
      className="bg-none border-none h3 hidden mt1 sm-show text-mine-shaft-o-64"
      onClick={(e) => {
        e.preventDefault();
        setSidebarVisible(!sidebarVisible);
      }}
      type="button"
    >
      <i className="fal fa-sliders-h" />
    </button>
  );

  return (
    <section>
      <div className="container container--main py4 sm-py3">
        <div className="flex flex-wrap mxn2">
          <div className="col-8 px2 sm-col-12">
            <h2 className="title flex flex-justify mb-50 sm-mb3">
              <div>
                { submittedQuery && articles.length > 0 && submitted === true && foundText }
              </div>

              <div>
                {sidebarBtn}
              </div>
            </h2>

            { articles.length > 0 && <Articles articles={articles} /> || emptyState }

            {
              articles.length > 0
              && (
                <div className="flex">
                  {
                    previous_page && <a
                      className="pager-prev"
                      href="#previousPage"
                      onClick={(e) => {
                        e.preventDefault();
                        send_request(request_params(previous_page));
                      }}
                    >
                      <i className="fal fa-chevron-left" />
                    </a>
                  }

                  <span className="pager-numbers">{ `${current_page} - ${total_pages}` }</span>

                  {
                    next_page && <a
                      className="pager-next"
                      href="#nextPage"
                      onClick={(e) => {
                        e.preventDefault();
                        send_request(request_params(next_page));
                      }}
                    >
                      <i className="fal fa-chevron-right" />
                    </a>
                  }
                </div>
              )
            }
          </div>

          <div
            className={`col-4 px2 sm-col-12 ${sidebarVisible && 'bg-white bottom-0 fixed left-0 overflow-y-auto px3 sm-py3 right-0 top-0 z7' || 'sm-hide'}`}
          >
            <div className="hidden sm-flex flex-justify sm-mb3">

              {sidebarBtn}
            </div>

            <div className="flex mb2 relative sm-mt3">
              <i className="fal fa-search absolute left-0 ml2 top-50p transform-vertical-center" />

              <input
                className="input mr2"
                type="text"
                placeholder="Keresés..."
                onChange={e => update_query(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') send_request(request_params());
                }}
                style={{ paddingLeft: '45px' }}
                value={query}
                maxLength="20"
              />

              <button
                className="btn btn--primary btn--px-20"
                onClick={(e) => {
                  e.preventDefault();
                  send_request(request_params());
                }}
                type="button"
              >
                Küldés
              </button>
            </div>

            <span className="text-mine-shaft-o-64">Mikortól</span>
            <DatePicker
              selected_date={start_date}
              set_date={set_start_date}
              input_props={{ className: 'input mb-24' }}
            />

            <span className="text-mine-shaft-o-64">Meddig</span>
            <DatePicker
              selected_date={end_date}
              set_date={set_end_date}
              input_props={{ className: 'input mb-40' }}
            />

            <ArticleTags
              article_tags={props.article_tags}
              selected_tag_ids={selected_tag_ids}
              select_tag={select_tag}
            />

            <p className="bold mb2">Korábbi bejegyzések</p>

            {
              Object.keys(props.articles_by_year).sort((a, b) => b - a).map(year => <ArticlesByYear
                key={year}
                year={parseInt(year)}
                articles_by_month={props.articles_by_year[year]}
              />)
            }
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticlesContainer;
